<template>
  <v-container id="contact" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0 main">
      <v-col cols="11" md="10" lg="7" class="ma-auto pa-0">
        <v-row class="pa-3">
          <v-col cols="12" md="6" order="3" order-md="1" data-aos="slide-right">
            <h1 class="text--secondary font-weight-medium py-4">{{$t("haveaquestion")}}?</h1>
            <div class="text-h7 font-weight-light my-8">{{$t("contactinfo")}}</div>

            <!-- Contact Us Form -->
            <contact-us-form data-aos="fade-right" :isDark="false" :type="'contact'"></contact-us-form>
            <!-- Contact Us Form -->
            
          </v-col>

          <v-col cols="12" md="1" class="text-center" order="2">
            <v-divider class="d-none d-md-block ma-auto" vertical data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1500"></v-divider>
            <v-divider class="d-block d-md-none ma-auto" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1500"></v-divider>
          </v-col>

          <v-col cols="12" md="5" class="py-md-8" order="1" order-md="3" data-aos="slide-left">
            <v-img class="pa-0 mb-12 " width="250" height="auto" src="../assets/images/logo.png" :alt="$t('logoalt')" data-aos="zoom-in"></v-img>

            <!-- Contact Us Info -->
            <contact-us-info></contact-us-info>
            <!-- Contact Us Info -->

          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../components/ContactUsForm.vue'
import contactusinfo from '../components/ContactUsInfo.vue';
export default {
  name: "Contact",
  data() {
    return {
    };
  },
  components: {
    'contact-us-form':contactusform,
    'contact-us-info':contactusinfo,
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    copy(item) {
      navigator.clipboard.writeText(item).then(()=>{
        this.$root.$emit('callSnackbar', { icon:'mdi-content-copy', state:true, content:this.$t('copiedtoclipboard'), type:'dark'});
      })
    },
  },
};
</script>
<style scoped>
@import url('../assets/styles/contact.css');
</style>