<template>
  <v-container id="contactusinfo" class="ma-auto" fluid>
    <v-skeleton-loader type="heading" min-width="500" v-if="!company || company_loading"></v-skeleton-loader>
    <span class="title font-weight-medium custom-accent--text" v-else>{{company.title}}</span>
    <span class="text-h6 text--secondary text-center text-md-left pt-2 d-flex">{{$t("ouroffices")}}:</span>
    <div v-if="addresses.length==0 || company_address_loading">
      <v-skeleton-loader type="image" min-width="500" height="120"></v-skeleton-loader>
    </div>
    <div v-else>
      <div class="font-weight-light pa-0 ma-0 pb-4 d-inline-flex" v-for="item in addresses" :key="item.id">
        <v-tooltip right class="mr-1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on"  :href="`http://maps.google.com/?q=`+item.address" :title="$t('openinmap')" target="_blank" icon>
              <v-icon>
                mdi-map-marker
              </v-icon>
            </v-btn>
          </template>
          <span>{{$t("openinmap")}}</span>
        </v-tooltip>
        <span class="my-auto">{{item.address}}</span>
        <v-tooltip right class="mr-1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="ml-auto" :title="$t('copytoclipboard')" icon @click="copy(item.address)">
              <v-icon small>
                mdi-content-copy
              </v-icon>
            </v-btn>
          </template>
          <span>{{$t("copytoclipboard")}}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="text-center text-md-left">
      <span class="text-h6 text--secondary pt-2 d-flex">{{$t("contactusat")}}:</span>
      <div v-if="!company || company_loading" class="d-flex">
        <v-skeleton-loader type="avatar" v-for="i in 2" :key="i" class="mr-1"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-tooltip right class="mr-1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :href="`tel:`+company.phone" class="success--text mr-2" :title="company.phone" dark v-bind="attrs" v-on="on" icon>
              <v-icon>
                mdi-phone
              </v-icon>
            </v-btn>
          </template>
          <span>{{company.phone}}</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :href="`mailto:`+company.email" class="secondary--text mr-2" :title="company.email" dark v-bind="attrs" v-on="on" icon>
              <v-icon>
                mdi-mail
              </v-icon>
            </v-btn>
          </template>
          <span>{{company.email}}</span>
        </v-tooltip>
      </div>
      <span class="text-h6 text--secondary pt-2 d-flex">{{$t("followuson")}}:</span>
      <div v-if="socials.length==0 || company_social_loading" class="d-flex">
        <v-skeleton-loader type="avatar" v-for="i in 3" :key="i" class="mr-1"></v-skeleton-loader>
      </div>
      <div v-else>
        <span v-for="social in socials" :key="social.name" class="mr-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :href="social.url" target="_blank" :title="social.name" v-bind="attrs" v-on="on" icon>
                  <v-icon :color="social.color">
                    {{social.icon}}
                  </v-icon>
                </v-btn>
            </template>
            <span>{{social.name}}</span>
          </v-tooltip>
        </span>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "ContactUsInfo",
  data() {
    return {
      company:null,
      socials:[],
      addresses:[],
    };
  },
  components: {},
  async created() {
    await this.retrieveCompanies().then(()=>{
      this.company = this.companies[0];
    });
    await this.retrieveCompanyAddresses().then(()=>{
      this.addresses = this.company_addresses.filter(item=>{return item.company==this.company.id});
    });
    await this.retrieveCompanySocials().then(()=>{
      this.socials = this.company_socials.filter(item=>{return item.company==this.company.id});
    });
  },
  computed: {
    ...mapGetters('company', {
      companies:'getCompanies'
    }),
    ...mapState("company", {
      company_loading: "loading"
    }),
    ...mapGetters('company_address', {
      company_addresses:'getCompanyAddresses'
    }),
    ...mapState("company_address", {
      company_address_loading: "loading"
    }),
    ...mapGetters('company_social', {
      company_socials:'getCompanySocials'
    }),
    ...mapState("company_social", {
      company_social_loading: "loading"
    }),
  },
  watch: {},
  methods: {
    copy(item) {
      navigator.clipboard.writeText(item).then(()=>{
        this.$root.$emit('callSnackbar', { icon:'mdi-content-copy', state:true, content:this.$t('copiedtoclipboard'), type:'dark'});
      })
    },

    ...mapActions("company", ["addCompany","retrieveCompanies"]),
    ...mapActions("company_address", ["addCompanyAddress","retrieveCompanyAddresses"]),
    ...mapActions("company_social", ["addCompanySocial","retrieveCompanySocials"]),
  },
};
</script>
 
