var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-auto",attrs:{"id":"contactusinfo","fluid":""}},[(!_vm.company || _vm.company_loading)?_c('v-skeleton-loader',{attrs:{"type":"heading","min-width":"500"}}):_c('span',{staticClass:"title font-weight-medium custom-accent--text"},[_vm._v(_vm._s(_vm.company.title))]),_c('span',{staticClass:"text-h6 text--secondary text-center text-md-left pt-2 d-flex"},[_vm._v(_vm._s(_vm.$t("ouroffices"))+":")]),(_vm.addresses.length==0 || _vm.company_address_loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"image","min-width":"500","height":"120"}})],1):_c('div',_vm._l((_vm.addresses),function(item){return _c('div',{key:item.id,staticClass:"font-weight-light pa-0 ma-0 pb-4 d-inline-flex"},[_c('v-tooltip',{staticClass:"mr-1",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":"http://maps.google.com/?q="+item.address,"title":_vm.$t('openinmap'),"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-map-marker ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("openinmap")))])]),_c('span',{staticClass:"my-auto"},[_vm._v(_vm._s(item.address))]),_c('v-tooltip',{staticClass:"mr-1",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"title":_vm.$t('copytoclipboard'),"icon":""},on:{"click":function($event){return _vm.copy(item.address)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("copytoclipboard")))])])],1)}),0),_c('div',{staticClass:"text-center text-md-left"},[_c('span',{staticClass:"text-h6 text--secondary pt-2 d-flex"},[_vm._v(_vm._s(_vm.$t("contactusat"))+":")]),(!_vm.company || _vm.company_loading)?_c('div',{staticClass:"d-flex"},_vm._l((2),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"mr-1",attrs:{"type":"avatar"}})}),1):_c('div',[_c('v-tooltip',{staticClass:"mr-1",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"success--text mr-2",attrs:{"href":"tel:"+_vm.company.phone,"title":_vm.company.phone,"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-phone ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.company.phone))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary--text mr-2",attrs:{"href":"mailto:"+_vm.company.email,"title":_vm.company.email,"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-mail ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.company.email))])])],1),_c('span',{staticClass:"text-h6 text--secondary pt-2 d-flex"},[_vm._v(_vm._s(_vm.$t("followuson"))+":")]),(_vm.socials.length==0 || _vm.company_social_loading)?_c('div',{staticClass:"d-flex"},_vm._l((3),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"mr-1",attrs:{"type":"avatar"}})}),1):_c('div',_vm._l((_vm.socials),function(social){return _c('span',{key:social.name,staticClass:"mr-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":social.url,"target":"_blank","title":social.name,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":social.color}},[_vm._v(" "+_vm._s(social.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(social.name))])])],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }